<template>
  <VTextFieldOutlined
    v-bind="$attrs"
    v-on="$listeners"
    label="URL"
    :value="value"
    @input="(e) => $emit('input', e)"
  />
</template>

<script>
  import { VTextFieldOutlined } from '@/shared/ui'

  export default {
    components: {
      VTextFieldOutlined
    },
    props: {
      value: {
        type: String
      }
    }
  }
</script>
